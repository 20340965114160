import styled from "styled-components"
import { mq } from "styles"

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BackgroundVideo = styled.video`
  z-index: -1;
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: #a6c4d4;
`

export const Content = styled.div`
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  text-align: center;
  margin-left: 16px;
  margin-right: 16px;
  h2 {
    color: var(--text-headline-dark);
    margin-bottom: 12px;
  }
  a {
    color: var(--text-links-dark);
  }
  ${mq.minWidth("md")} {
    margin-bottom: 16px;
  }
`
