import { useCommonMediaQuery } from "hooks/useMediaQuery"
import Mobile404VideoMp4 from "assets/videos/404-mobile.mp4"
import Tablet404VideoMp4 from "assets/videos/404-tablet.mp4"
import Desktop404VideoMp4 from "assets/videos/404-desktop.mp4"
import Mobile404VideoOgg from "assets/videos/404-mobile.mp4"
import Tablet404VideoOgg from "assets/videos/404-tablet.mp4"
import Desktop404VideoOgg from "assets/videos/404-desktop.mp4"

export interface Source {
  src: string
  type: string
}

const useGetVideoSources = (): Source[] => {
  const { isTablet, isDesktop } = useCommonMediaQuery()

  if (isDesktop) {
    return [
      {
        src: Desktop404VideoMp4,
        type: "video/mp4",
      },
      {
        src: Desktop404VideoOgg,
        type: "video/ogg",
      },
    ]
  }

  if (isTablet) {
    return [
      {
        src: Tablet404VideoMp4,
        type: "video/mp4",
      },
      {
        src: Tablet404VideoOgg,
        type: "video/ogg",
      },
    ]
  }

  return [
    {
      src: Mobile404VideoMp4,
      type: "video/mp4",
    },
    {
      src: Mobile404VideoOgg,
      type: "video/ogg",
    },
  ]
}

export default useGetVideoSources
