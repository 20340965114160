import React from "react"
import { Link } from "gatsby"
import useGetVideoSources from "./useGetVideoSources"
import { Container, BackgroundVideo, Content } from "./NotFound.styles"

export const NotFound = () => {
  const videoSources = useGetVideoSources()

  return (
    <Container>
      <BackgroundVideo
        playsInline
        autoPlay
        muted
        loop
        key={videoSources[0].src}
      >
        {videoSources.map(videoSource => (
          <source
            key={videoSource.src}
            src={videoSource.src}
            type={videoSource.type}
          />
        ))}
      </BackgroundVideo>
      <Content>
        <h2>There’s nothing here</h2>
        <p>
          Empty space. A chance for a pause and a deep breath.
          <br />
          When you’re ready, find your way by starting at{" "}
          <Link to="/">home</Link>.
        </p>
      </Content>
    </Container>
  )
}
