import React from "react"
import { graphql } from "gatsby"
import { Query } from "typings/graphql"
import { App } from "components/App/App"
import { setTheme } from "helpers/Theme"
import { NotFound } from "components/NotFound/NotFound"

type PageProps = {
  data: Query
}

const PageNotFound = ({ data }: PageProps): React.ReactElement => {
  const theme = setTheme(
    data.sanityTypography,
    data.sanityDarkTheme,
    data.sanityLightTheme,
    data.sanityGlobalTheme
  )

  const seoData = {
    seo: data.allSanityPage?.nodes[0]?.seo,
    path: "/",
  }

  return (
    <App theme={theme} layout="page" mode="dark" seo={seoData}>
      <NotFound />
    </App>
  )
}

export const pageQuery = graphql`
  query PageNotFound {
    sanityTypography {
      ...Typography
    }
    sanityDarkTheme {
      ...DarkTheme
    }
    sanityLightTheme {
      ...LightTheme
    }
    sanityGlobalTheme {
      ...GlobalTheme
    }
    allSanityPage(filter: { path: { eq: "/" } }) {
      nodes {
        seo {
          ...SanitySeo
        }
      }
    }
  }
`

export default PageNotFound
